/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-radius {
    border-radius: 0;
}

.divider-vertical {
    background: rgba(0, 0, 0, 0.12);
    height: 24px;
    width: 1px;
    margin: 0 8px;
}

// Soft hide (don't remove from DOM flow)
.hidden {
    visibility: hidden;
    opacity: 0;
}

[fill-width] {
    min-width: 100%;
}

.fill-width {
    width: 100% !important;
}

[fill-height] {
    min-height: 100%;
}

.full-height {
    height: 100% !important;
}

// Images in links
a {

    img {
        display: block;
        outline: none;
    }
}

// Responsive images
img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}

// Scrollable
.scrollable {
    display: block;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.only-x {
        overflow-x: auto;
        overflow-y: hidden;
    }

    &.only-y {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Flex Scrollable (without display:block)
.flex-scrollable {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.only-x {
        overflow-x: auto;
        overflow-y: hidden;
    }

    &.only-y {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Background Icon
.background-image-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

// Classic vertical align
.align-vertical {

    &:before {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
    }

    .align-vertical-top,
    .align-vertical-middle,
    .align-vertical-bottom {
        display: inline-block;
    }

    .align-vertical-top {
        vertical-align: top;
    }

    .align-vertical-middle {
        vertical-align: middle;
    }

    .align-vertical-bottom {
        vertical-align: bottom;
    }
}

// Padding helpers
// Multiples of 5, max:100
@for $i from 0 through 20 {

    .padding-#{$i * 5},
    .p-#{$i * 5} {
        padding: #{($i * 5) + 'px'} !important;
    }

    .padding-top-#{$i * 5},
    .pt-#{$i * 5} {
        padding-top: #{($i * 5) + 'px'} !important;
    }

    .padding-right-#{$i * 5},
    .pr-#{$i * 5} {
        padding-right: #{($i * 5) + 'px'} !important;
    }

    .padding-bottom-#{$i * 5},
    .pb-#{$i * 5} {
        padding-bottom: #{($i * 5) + 'px'} !important;
    }

    .padding-left-#{$i * 5},
    .pl-#{$i * 5} {
        padding-left: #{($i * 5) + 'px'} !important;
    }

    .padding-horizontal-#{$i * 5},
    .ph-#{$i * 5} {
        padding-left: #{($i * 5) + 'px'} !important;
        padding-right: #{($i * 5) + 'px'} !important;
    }

    .padding-vertical-#{$i * 5},
    .pv-#{$i * 5} {
        padding-top: #{($i * 5) + 'px'} !important;
        padding-bottom: #{($i * 5) + 'px'} !important;
    }
}

// Multiples of 4, Max: 96, Excluding 0 and multiples of 5
@for $i from 1 through 24 {

    @if (($i * 4) % 5 != 0) {
        .padding-#{$i * 4},
        .p-#{$i * 4} {
            padding: #{($i * 4) + 'px'}!important;
        }

        .padding-top-#{$i * 4},
        .pt-#{$i * 4} {
            padding-top: #{($i * 4) + 'px'}!important;
        }

        .padding-right-#{$i * 4},
        .pr-#{$i * 4} {
            padding-right: #{($i * 8) + 'px'}!important;
        }

        .padding-bottom-#{$i * 4},
        .pb-#{$i * 4} {
            padding-bottom: #{($i * 4) + 'px'}!important;
        }

        .padding-left-#{$i * 4},
        .pl-#{$i * 4} {
            padding-left: #{($i * 4) + 'px'}!important;
        }

        .padding-horizontal-#{$i * 4},
        .ph-#{$i * 4} {
            padding-left: #{($i * 4) + 'px'}!important;
            padding-right: #{($i * 4) + 'px'}!important;
        }

        .padding-vertical-#{$i * 4},
        .pv-#{$i * 4} {
            padding-top: #{($i * 4) + 'px'}!important;
            padding-bottom: #{($i * 4) + 'px'}!important;
        }
    }
}

// Margin helpers
// Multiples of 5, max:100
@for $i from 0 through 20 {

    .margin-#{$i * 5},
    .m-#{$i * 5} {
        margin: #{($i * 5) + 'px'}!important;
    }

    .margin-top-#{$i * 5},
    .mt-#{$i * 5} {
        margin-top: #{($i * 5) + 'px'}!important;
    }

    .margin-right-#{$i * 5},
    .mr-#{$i * 5} {
        margin-right: #{($i * 5) + 'px'}!important;
    }

    .margin-bottom-#{$i * 5},
    .mb-#{$i * 5} {
        margin-bottom: #{($i * 5) + 'px'}!important;
    }

    .margin-left-#{$i * 5},
    .ml-#{$i * 5} {
        margin-left: #{($i * 5) + 'px'}!important;
    }

    .margin-horizontal-#{$i * 5},
    .mh-#{$i * 5} {
        margin-left: #{($i * 5) + 'px'}!important;
        margin-right: #{($i * 5) + 'px'}!important;
    }

    .margin-vertical-#{$i * 5},
    .mv-#{$i * 5} {
        margin-top: #{($i * 5) + 'px'}!important;
        margin-bottom: #{($i * 5) + 'px'}!important;
    }
}

// Multiples of 4, Max: 96, Excluding 0 and multiples of 5
@for $i from 1 through 24 {

    @if (($i * 4) % 5 != 0) {

        .margin-#{$i * 4},
        .m-#{$i * 4} {
            margin: #{($i * 4) + 'px'}!important;
        }

        .margin-top-#{$i * 4},
        .mt-#{$i * 4} {
            margin-top: #{($i * 4) + 'px'}!important;
        }

        .margin-right-#{$i * 4},
        .mr-#{$i * 4} {
            margin-right: #{($i * 4) + 'px'}!important;
        }

        .margin-bottom-#{$i * 4},
        .mb-#{$i * 4} {
            margin-bottom: #{($i * 4) + 'px'}!important;
        }

        .margin-left-#{$i * 4},
        .ml-#{$i * 4} {
            margin-left: #{($i * 4) + 'px'}!important;
        }

        .margin-horizontal-#{$i * 4},
        .mh-#{$i * 4} {
            margin-left: #{($i * 4) + 'px'}!important;
            margin-right: #{($i * 4) + 'px'}!important;
        }

        .margin-vertical-#{$i * 4},
        .mv-#{$i * 4} {
            margin-top: #{($i * 4) + 'px'}!important;
            margin-bottom: #{($i * 4) + 'px'}!important;
        }
    }
}

// Height and width helpers
// Multiples of 5, Max: 500
@for $i from 0 through 100 {

    .height-#{$i * 5},
    .h-#{$i * 5} {
        height: #{($i * 5) + 'px'} !important;
        min-height: #{($i * 5) + 'px'} !important;
        max-height: #{($i * 5) + 'px'} !important;
    }

    .width-#{$i * 5},
    .w-#{$i * 5} {
        width: #{($i * 5) + 'px'} !important;
        min-width: #{($i * 5) + 'px'} !important;
        max-width: #{($i * 5) + 'px'} !important;
    }
}

// Height and width helpers
// Multiples of 4, Max: 800, Excluding 0 and multiples of 5
@for $i from 1 through 200 {

    @if (($i * 4) % 5 != 0) {

        .height-#{$i * 4},
        .h-#{$i * 4} {
            height: #{($i * 4) + 'px'} !important;
            min-height: #{($i * 4) + 'px'} !important;
            max-height: #{($i * 4) + 'px'} !important;
        }

        .width-#{$i * 4},
        .w-#{$i * 4} {
            width: #{($i * 4) + 'px'} !important;
            min-width: #{($i * 4) + 'px'} !important;
            max-width: #{($i * 4) + 'px'} !important;
        }
    }
}


@for $i from 0 through 10 {
    .flex-#{$i},
    .fl-#{$i} {
        flex: #{($i)} !important;
    }
}


@for $i from 1 through 70 {
    .font-size-#{$i},
    .fs-#{$i} {
        font-size: #{($i) + 'px'} !important;
    }
}


@for $i from 1 through 70 {
    .line-height-#{$i},
    .lh-#{$i} {
        line-height: #{($i) + 'px'} !important;
    }
}

// Border helpers
$border-style: 1px solid rgba(0, 0, 0, 0.12);

.border,
.b {
    border: $border-style;
}

.border-top,
.bt {
    border-top: $border-style;
}

.border-right,
.br {
    border-right: $border-style;
}

.border-bottom,
.bb {
    border-bottom: $border-style;
}

.border-left,
.bl {
    border-left: $border-style;
}

.border-horizontal,
.bh {
    border-left: $border-style;
    border-right: $border-style;
}

.border-vertical,
.bv {
    border-top: $border-style;
    border-bottom: $border-style;
}

// Border-radius helpers
@for $i from 1 through 12 {

    .brad-#{$i} {
        border-radius: #{$i + 'px'}
    }
}

.relative {
  position: relative !important;
}

.underline {
    text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-align-start {
    align-items: flex-start!important;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.text-align-centered {
    text-align: center;
}

.medium {
    font-weight: 500!important;
}

.bold {
    font-weight: 700!important;
}

.thin {
    font-weight: 400!important;
}

.green {
    color: #5ECB9E;
}

.dark-green {
    color: #029094;
}
.color-primary {
    color: #1C434F;
}

.no-wrap {
    white-space: nowrap;
}

.no-decoration {
    text-decoration: none!important;
}
